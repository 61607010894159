import Link from 'next/link';
import { CategoryGridResponse } from 'src/lib/interface';
import PharmacyImage from 'components/PharmacyImage';
import { categoryUrlGenerator, categoryUrlGeneratorWithUrlKey } from 'utils/category-url';
const CategoriesGrid = ({ cmsItem, push, t }: any) => {
  const id = cmsItem?.target?.split('=')[1];
  return (
    <div className='bg-[#F7F7F8] my-2'>
      <div className='block py-10 px-5 mx-auto max-w-[1152px]'>
        <div className='flex items-center justify-between px-2'>
          <h1 className='text-gray-900 mx-2 text-[20px] font-bold'>{cmsItem.title}</h1>
          {id && (
            <button
              onClick={() => push(categoryUrlGenerator(cmsItem.title, id))}
              className='text-primary font-[600] mx-2'
            >
              {t('see_all')} {'>'}
            </button>
          )}
        </div>

        <div className='grid gap-5 grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8'>
          {cmsItem?.data
            ?.sort((a, b) => a.position - b.position)
            ?.map((item: CategoryGridResponse, key: number) => (
              <Link key={item.entity_id} href={categoryUrlGeneratorWithUrlKey(item?.url_key)} prefetch={false}>
                <a className='rounded-lg w-full bg-white group h-auto'>
                  {/* eslint-disable-next-line */}
                  <div className='w-full grid '>
                    <PharmacyImage
                      imageWidth={103}
                      imageHeight={103}
                      objectFit='cover'
                      src={item.image}
                      alt={item.name}
                    />
                  </div>
                  <div className='group-hover:bg-[#35B050] rounded-b-lg h-[50px] w-full flex items-center'>
                    <p className='h-[56px] font-bold text-caption-1 text-dark-gray group-hover:text-white py-3 px-[7.5px]'>
                      {item.name}
                    </p>
                  </div>
                </a>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CategoriesGrid;
