import clsx from 'clsx';
import Link from 'next/link';
import PharmacyImage from 'components/PharmacyImage';
export const GradientBox = (props: {
  title?: string;
  image: string;
  className?: string;
  long?: boolean;
  href?: string;
  width?: number;
  height?: number;
}) => {
  return (
    <Link href={props.href || ''} prefetch={false}>
      <a
        aria-label={'single_banner'}
        className={clsx(
          `lg:rounded-lg block overflow-hidden w-full relative max-h-[312px]`,
          props.long && 'max-h-[300px] contain flex',
          props.className,
        )}
      >
        {/* eslint-disable-next-line */}
        <PharmacyImage
          imageWidth={props.width}
          imageHeight={props.height}
          // layout='responsive'
          // objectFit='contain'
          className='w-full'
          src={props.image}
          quality={100}
          alt={props.image}
        />
        {props.title ? (
          <h4 className='text-[#024469] text-title-3 font-bold absolute px-[18px] py-4 z-30 bottom-0'>{props.title}</h4>
        ) : (
          ''
        )}
      </a>
    </Link>
  );
};
