import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import ProductItem from './ProductItem';
import { IProductItem } from 'lib/interface';
import { useTranslation } from 'next-i18next';
import { memo, useMemo } from 'react';

interface ICarouselProps {
  products: IProductItem[];
  item_list_name?: string;
  numOfRecords?: number;
  bg?: string;
  setShowResults: any;
}

const ProductCarousel = ({
  products,
  bg = '#fff',
  setShowResults,
  item_list_name,
  numOfRecords = 6,
}: ICarouselProps) => {
  const { t } = useTranslation('common');

  const renderProductItems = useMemo(() => {
    if (products?.length === 0) {
      return <div className='text-center'>{t('no_data_found')}</div>;
    }

    return products?.map((product) => {
      return (
        <SwiperSlide key={product.sku}>
          <ProductItem setShowResults={setShowResults} bg={bg} product={product} item_list_name={item_list_name} />
        </SwiperSlide>
      );
    });
  }, [products]);

  return (
    <Swiper
      slidesPerView={numOfRecords}
      spaceBetween={10}
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        '@0.00': {
          slidesPerView: 2.1,
          spaceBetween: 10,
        },
        '@0.75': {
          slidesPerView: 3.5,
          spaceBetween: 20,
        },
        '@1.00': {
          slidesPerView: 5,
          spaceBetween: 40,
        },
        '@1.50': {
          slidesPerView: numOfRecords,
          spaceBetween: 40,
        },
      }}
      style={{ backgroundColor: bg }}
      className={`productCarousel bg-[${bg}] md:bg-[${bg}]`}
      wrapperClass={`pl-4 rtl:pl-0 rtl:pr-4 lg:pl-0 rtl:lg:pr-0`}
    >
      {renderProductItems}
    </Swiper>
  );
};

export default memo(ProductCarousel);
