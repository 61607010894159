import RichTextComp from 'components/RichText/RichText';

const RichText = ({ cmsItem }: any) => {
  return (
    <div className='block py-10 px-5 mx-auto max-w-[1152px]'>
      <RichTextComp richTextData={cmsItem.content} />
    </div>
  );
};

export default RichText;
