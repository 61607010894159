import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { CategoryItem } from './Widgets/CategoryItem';
import { CategoryGridResponse } from 'src/lib/interface';
import React, { useState } from 'react';
import { Navigation } from 'swiper';
import 'swiper/css/navigation';
import { categoryUrlGeneratorWithUrlKey } from 'utils/category-url';

interface ICarouselProps {
  categories: CategoryGridResponse[];
  isPLP?: boolean;
  push: any;
}

const CategoryCarousel = ({ categories, isPLP, push }: ICarouselProps) => {
  const [carouselData, _] = useState(categories?.filter((item) => item.product_count > 0));

  return (
    <Swiper
      slidesPerView={2.5}
      spaceBetween={10}
      grabCursor={true}
      modules={[Navigation]}
      navigation={{
        prevEl: '.swiper-cat-button-prev',
        nextEl: '.swiper-cat-button-next',
      }}
      breakpoints={{
        '@0.00': {
          slidesPerView: 2.5,
          spaceBetween: 10,
        },
        '@0.75': {
          slidesPerView: 3.5,
          spaceBetween: 20,
        },
        '@1.00': {
          slidesPerView: 6,
          spaceBetween: 30,
        },
        '@1.50': {
          slidesPerView: 10,
          spaceBetween: 10,
        },
      }}
      className='productCarousel'
    >
      {carouselData?.map((category) => {
        return (
          <SwiperSlide key={category.entity_id}>
            <CategoryItem
              key={category.entity_id}
              title={category.name}
              image={category.image}
              href={categoryUrlGeneratorWithUrlKey(category.url_key)}
              className='mx-2'
              isPLP={isPLP}
              push={push}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default CategoryCarousel;
