import { SingleBanner as Banner } from 'components/SingleBanner';

const SingleBanner = ({ cmsItem, pickedImgUrl }: any) => {
  return (
    <div className='block py-10 px-5 max-w-[350px]'>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4'>
        <Banner image={cmsItem[pickedImgUrl]} href={cmsItem.target} key={cmsItem.id} long />
      </div>
    </div>
  );
};

export default SingleBanner;
