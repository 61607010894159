import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Thumbs, Pagination, Autoplay } from 'swiper';
import Link from 'next/link';
import PharmacyImage from './PharmacyImage';

import { DeviceDetector } from 'utils/dd';
import { useContext } from 'react';
interface ISliderProps {
  data: {
    format: string;
    id: string;
    target: string;
    url: string;
    mobileUrl: string;
  }[];
  isPLP?: boolean;
  width?: string;
  bannerClassName?: string;
  slideChangeClassName?: string;
}

export const MainSlider = ({
  isPLP = false,
  data,
  bannerClassName = 'bg-dark-white',
  width = '',
  slideChangeClassName = 'flex mt-5 mx-auto justify-center',
}: ISliderProps) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const Device = useContext(DeviceDetector);

  const isMobile = Device.isMobile;
  const pickedImgUrl = isMobile ? 'mobileUrl' : 'url';

  return (
    <div className={`${bannerClassName} ${isPLP ? 'lg:py-5' : 'lg:py-8'}`}>
      <div className='container mx-auto relative max-w-auto'>
        <Swiper
          spaceBetween={10}
          navigation={!isMobile}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Pagination, Navigation, Thumbs, Autoplay]}
          pagination={{
            clickable: true,
          }}
          speed={1000}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          style={{ width: width }}
          className='mySwiper2'
        >
          <div
            className={`rounded-[18px] overflow-hidden flex mx-16 relative ${
              isMobile ? 'h-[360px]' : 'h-[440px] md:h-[420px]'
            }`}
          >
            {data?.map((item, key) => {
              // prod/mobile 1080 × 720 // prod/web 992 × 449
              // beta/mobile 520 × 320 // beta/web 992 × 449
              const isBeta = item?.url?.includes('beta');
              const width = isMobile ? (isBeta ? 520 : 1080) : 994;
              const height = isMobile ? (isBeta ? 320 : 720) : 449;
              return (
                <div key={item.id + key}>
                  <SwiperSlide key={item.id ?? item.url + key}>
                    <Link href={item.target || '#'} className='w-full absolute left-0 top-0'>
                      <a aria-label={item.id}>
                        <PharmacyImage
                          imageWidth={width}
                          imageHeight={height}
                          src={item[pickedImgUrl]}
                          quality={100}
                          alt={item.id ?? item.url}
                          priority={key == 0}
                        />
                      </a>
                    </Link>
                  </SwiperSlide>
                </div>
              );
            })}
          </div>
        </Swiper>
        {!isPLP && (
          <div className={slideChangeClassName}>
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={10}
              slidesPerView={4}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className='mySwiper'
            >
              {data?.map((item, key) => (
                <SwiperSlide key={item.id ?? item.url + key ?? key}>
                  <div className='sliderThumb hidden lg:block cursor-pointer'>
                    <PharmacyImage
                      imageWidth={640}
                      imageHeight={289}
                      objectFit='cover'
                      src={item[pickedImgUrl]}
                      alt={item.id ?? item.url}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </div>
    </div>
  );
};
