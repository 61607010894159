import { truncateText } from 'lib/helpers/functions/TextTruncate';
import Link from 'next/link';
import PharmacyImage from 'components/PharmacyImage';

export const CategoryItem = (props: {
  image: string;
  title: string;
  href: string;
  className?: string;
  isPLP?: boolean;
  push: any;
}) => {
  const { image, title, href = '#', className, isPLP, push } = props;

  return (
    <Link href={href} prefetch={false}>
      <a
        onClick={(e) => {
          if (isPLP) {
            e.preventDefault();
            push(href).then(() => {
              window.location.reload();
            });
          }
        }}
        className={`flex w-[93px] items-center justify-center ${className}`}
      >
        <div className='flex flex-col items-center'>
          <div className='w-[75px] h-[75px] rounded-full overflow-hidden drop-shadow-item'>
            <PharmacyImage imageWidth={75} imageHeight={75} objectFit='cover' src={image} alt={title} />
          </div>
          <p className='text-caption-1 text-[#0e0e0e] mt-[11px] whitespace-nowrap'>{truncateText(title, 13)}</p>
        </div>
      </a>
    </Link>
  );
};
