import { SingleBanner } from 'components/SingleBanner';

const FullBanner = ({ cmsItem, pickedImgUrl }: any) => {
  return (
    <div className='w-full max-h-[390px] lg:py-6'>
      <SingleBanner image={cmsItem[pickedImgUrl]} href={cmsItem.target} title={cmsItem.title} long />
    </div>
  );
};

export default FullBanner;
