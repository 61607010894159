import clsx from 'clsx';
import { selectContentEvent } from 'lib/helpers/gtm/events';
import Link from 'next/link';
import { GradientBox } from 'components/Widgets/GradientBox';
import { DeviceDetector } from 'utils/dd';
import { useContext } from 'react';

interface ISingleBanner {
  title?: string;
  image: string;
  long?: boolean;
  href?: string;
}

export const SingleBanner = ({ title, image, long, href }: ISingleBanner) => {
  const Device = useContext(DeviceDetector);

  const isMobile = Device.isMobile;
  return (
    <Link
      href={href || '#'}
      className={clsx('relative mx-auto mt-[50px] m-0', !long && 'max-w-[1152px]')}
      prefetch={false}
      aria-label='single-banner'
    >
      <div className='flex justify-center' onClick={() => selectContentEvent('single_banner', href)}>
        <div className='block'>
          <GradientBox
            width={isMobile ? 670 : 1440}
            height={isMobile ? 320 : 286}
            title={title}
            image={image}
            long={long}
            href={href}
          />
        </div>
      </div>
    </Link>
  );
};
